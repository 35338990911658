function PrivacyText() {
  return (
    <div className='privacy-text-wrap'>
      <h2 id='title'>Privacy</h2>
      <p>
        This Privacy Policy explains how personal information is collected,
        used, stored and with whom INTECH MANAGEMENT LIMITED, (‘iNTECH’, 'we,'
        'us,' and 'our') share that information. You don’t have to share any
        information with us, but to access and use the services available on our
        website and the iNPAY mobile application (the “Platforms”), we’ll need
        some information from you. This Policy also contains information about
        when we share your personal information with third parties (such as
        third party service providers and our affiliates).
      </p>
      <p>Last updated: December 5, 2024.</p>
      <h3 id='section-1'>1. Application of this Privacy Policy</h3>
      <p>
        This Privacy Policy applies generally to the business of iNTECH and
        serves to set out our approach to personal data, inclusive of personal
        data of our customers ('Users'). This Privacy Policy applies to the use
        of the Platforms, other online services and or any related services,
        sales, marketing, promotional or events, and social media activities
        (collectively, our 'Services'). This Privacy Policy forms part of our
        general Terms of Use.
      </p>
      <p>
        This Privacy Policy applies to your use of our Services regardless of
        means of access. You may access or use our Services through a desktop,
        laptop, mobile phone, tablet, or other consumer electronic device (each,
        a 'Device'). This Privacy Policy also applies to offline interactions
        with iNTECH.
      </p>
      <p>
        By accessing or using our Services, you agree to this Privacy Policy.
      </p>
      <h3 id='section-2'>2. Privacy and Data Protection</h3>
      <p>
        Protecting your privacy is very important to us. Please review our
        Privacy Policy in order to better understand our commitment to
        maintaining your privacy and protecting your data, as well as our use
        and disclosure of your information. If you receive information about
        another User through the Services, you must keep the information
        confidential. You may not disclose or distribute an iNTECH User’s
        information to a third party or use the information for marketing or
        other purposes without that User’s express consent.
      </p>
      <h3 id='section-3'>3. What information do we collect?</h3>
      <h4>Information you give us on iNPAY</h4>
      <p>
        We collect and use any information you supply when you interact with any
        of our Services touch points. As a User, we will also ask you to create
        a profile and account login information for your iNPAY account. To
        enable you carry out financial transactions, we will ask you to provide
        more information in order to open a bank account with VFD Microfinance
        Bank. In the process, you will share details like your name, mobile
        phone number, BVN, ID, address and a picture. When you provide your
        mobile phone number, we may ask for your consent to receive text
        messages relating to our Services on that number. Additionally, we may
        request explicit permission to see other information like your address
        book, location, photos and data from your camera.
      </p>

      <h4>Other information you give on the Platforms may include:</h4>
      <ul className='bullet-outline'>
        <li>
          Details you give when you sign up for a iNPAY profile and a bank
          account, like your BVN, name, date of birth, gender, phone number,
          residential address, and email address.
        </li>
        <li>Your profile picture.</li>
        <li>
          Our services may require us to collect a video of your face during a
          liveness check to confirm that you are the one setting up your iNPAY
          account or carrying out other sensitive activities on your iNPAY
          account (if you have one). This video may be shared with third-party
          service providers to facilitate our liveness check.
        </li>
        <li>
          The choice to upload a video of your face during liveness check may
          not be exercised. However, you should know that this will limit what
          you can do with your iNPAY account.
        </li>
        <li>
          We will not share the video of your face that we collect during a
          liveness check with advertisers or anyone else other than the
          third-party service providers that facilitates the liveness check
          process. ○If at any time you choose to withdraw your consent for us to
          use your video for your liveness check, please contact us at
          info@inpay.ng and we will delete it.
        </li>
        <li>
          Also, if you choose to delete your iNPAY account, we will delete any
          data we have previously collected from you including the video of your
          face uploaded during the liveness check.
        </li>
        <li>
          Information you give us through the in-app chat so we can help you.
        </li>
      </ul>

      <h4>Information we collect when you use the Platforms</h4>
      <ul className='bullet-outline'>
        <li>The mobile network and operating system you use</li>
        <li>Your IP address and device ID</li>
      </ul>

      <h4>Information we get from third parties</h4>
      <p>
        In our KYC (Know Your Customer) process, we run checks on the ID
        information you supply during signup. We may collect your Personal
        Information from a User where your Personal Information is filled in a
        Next-of-Kin form.
      </p>
      <h4>Information you give us when you contact us</h4>
      <p>
        If you contact us via other means than the in-app chat, we collect the
        following information so we can answer your questions or take action.
      </p>
      <ul className='bullet-outline'>
        <li>
          The phone number you’re calling from and the information you give us
          during the call
        </li>
        <li>
          The email address you use and the contents of your email and any
          attachments.
        </li>
        <li>
          Public details from your social media profile like Facebook, Instagram
          or Twitter if you reach out to us via these platforms and the contents
          of your messages or posts to us.
        </li>
      </ul>

      <h4>Information Automatically Collected</h4>
      <p>
        We or our service providers acting on our behalf may collect information
        about your use of our Services. This information may include Personal
        Information as well as statistical information that does not identify
        you ('Analytics'). Some Analytics may be correlated with Personal
        Information. When Analytics are, directly or indirectly, associated or
        combined with Personal Information, such Analytics will be considered
        Personal Information for purposes of this Privacy Policy.
      </p>
      <h3 id='section-4'>4. Security & Confidentiality</h3>
      <p>
        We maintain (and require service providers to maintain) generally
        accepted, reasonable, and appropriate standards to protect your personal
        information, both during transmission and once it is received. For
        example, we employ physical access controls, encryption, Internet
        firewalls, intrusion detection and network monitoring depending on the
        nature of the information and the scope of processing. In deciding what
        is reasonable and appropriate, we take into account the risks involved
        and the nature of the information. However, no security procedures or
        protocols are ever guaranteed to be 100% secure. In the event that we
        are required by law to inform you of a breach of your personal
        information we may notify you electronically, in writing, or by
        telephone, if permitted to do so by law.
      </p>
      <p>
        If you have any questions about the security of your personal
        information, you can contact us at info@inpay.ng
      </p>
      <h3 id='section-5'>5. How do we use your information? </h3>
      <p>
        In line with the Nigerian Data Protection Regulation, we are mandated to
        have a lawful basis for using your personal information. At least one of
        the following must apply: contractual or legal duty, legitimate
        interest, public interest, vital individual interest or consent.
      </p>
      <h4>Contractual Obligation</h4>
      <p>
        We collect certain data from you to fulfil the contract we have with you
        or to enter into a contract with you. We use this data to:
      </p>
      <ul className='bullet-outline'>
        <li>
          give you the services we agreed to in line with our terms and
          conditions.
        </li>
        <li>
          send you messages about your account and other services you use if you
          get in touch, or if we need to pass any information to you.
        </li>
        <li>
          Exercise our rights under contracts we’ve entered into with you, like
          managing, and receiving your money.
        </li>
        <li>Investigate and resolve complaints and other issues.</li>
      </ul>

      <h4>Legal Duty</h4>
      <p>
        We have to ensure we aren’t breaking any laws by preventing illegal
        activities like money laundering and fraud. To do this, we need your
        data to
      </p>
      <ul className='bullet-outline'>
        <li>confirm your identity when you sign up or get in touch.</li>
        <li>
          prevent illegal activities like money laundering, tax evasion and
          fraud.
        </li>
        <li>
          Keep records of information we hold about you in line with legal
          requirements.
        </li>
        <li>
          adhere to banking laws and regulations (this means we sometimes need
          to share customer details with regulators, tax authorities, law
          enforcement or other third parties as may be required by law).
        </li>
      </ul>

      <h4>Legitimate Interest</h4>
      <p>
        In some instances, we need to use the data you supply us for legitimate
        interests. This means we’re using your data for a reason which is in
        your interest, in accordance with applicable laws and doesn't override
        your privacy, interests or fundamental rights and freedoms.
      </p>
      <h4>Consent</h4>
      <p>
        For certain information, we’ll ask for your consent. We’ll ask for your
        consent to:
      </p>
      <ul className='bullet-list'>
        <li>
          Market and communicate our products and services. You can always
          unsubscribe from receiving these if you want to.
        </li>
        <li>
          Help protect you against fraud by tracking the location of your phone
          if you’ve authorized it.
        </li>
        <li>View your contact list for airtime purchases.</li>
      </ul>

      <h3 id='section-6'>6. When do we delete your data? </h3>
      <p>
        We will only hold your personal information on iNTECH’s systems for as
        long as is necessary to fulfill the purpose for which it was collected
        or to comply with legal, regulatory or internal policy requirements. You
        however have the right to request deletion of your personal information
        subject to any overriding legal or compliance requirements.
      </p>
      <h3 id='section-7'>7. What are your rights? </h3>
      <p>
        If you do not want to receive marketing email communications from us,
        you can opt-out by clicking on the “unsubscribe” link located at the
        bottom of our marketing emails or you may send a request to
        info@inpay.ng
      </p>
      <p>
        <strong>Right to Correct or Update Your Information:</strong> You may
        request that we correct or update any inaccurate or incomplete personal
        information by contacting info@inpay.ng.
      </p>
      <p>
        <strong>Your personal information rights are not absolute:</strong>
        Access to your rights under the applicable laws may be denied: (a) when
        denial of access is required or authorized by law; (b) when granting
        access would infringe another’s privacy; (c) to protect our rights and
        properties; (d) where the request is frivolous or vexatious, or for
        other legitimate reasons.
      </p>
      <p>
        <strong>Right not to provide consent or to withdraw consent:</strong> We
        may seek to rely on your consent in order to process certain personal
        information. Where we do so, you have the right not to provide your
        consent or to withdraw your consent at any time. This does not affect
        the lawfulness of the processing based on consent before its withdrawal.
      </p>
      <p>
        <strong>Right of access and/or portability:</strong> You have the right
        to access the personal information that we hold about you and, in some
        limited circumstances, have that data provided to you so that you can
        provide or port that data to another provider.
      </p>
      <p>
        <strong>Right of erasure:</strong> In certain circumstances, you have
        the right to the erasure of personal information that we hold about you
        (for example, if it is no longer necessary for the purposes for which it
        was originally collected).
      </p>
      <p>
        <strong>Right to object to processing:</strong> You have the right to
        request that we stop processing your personal information and/or stop
        sending you marketing communications.
      </p>
      <p>
        <strong>Right to rectification:</strong> You have the right to require
        us to correct any inaccurate or incomplete personal information.
      </p>
      <p>
        <strong>Right to restrict processing:</strong> you have the right to
        request that we restrict processing of your personal information in
        certain circumstances (for example, where you believe that the personal
        information we hold about you is not accurate or lawfully held).
      </p>
      <p>
        <strong>
          Right to lodge a complaint to your local Data Protection Authority:
        </strong>
        You have the right to lodge a complaint with your national Data
        Protection Authority or equivalent regulatory body.
      </p>
      <p>
        To assert your privacy rights, please email info@inpay.ng Please note
        that to protect your privacy and security, we must be able to verify
        your identity before we can process your request to exercise any of the
        privacy rights that you may be entitled to under the applicable laws. We
        may conduct the verification process by email or phone, and we may ask
        you to provide information such as your name, contact information, and
        any additional relevant information based on your relationship with us.
        You may also use an authorized agent to submit a request to opt out on
        your behalf if you provide the authorized agent signed written
        permission to do so.
      </p>
      <h3 id='section-8'>8. Children’s Personal Information</h3>
      <p>
        We do not knowingly collect any personal information from children under
        the age of 18. If you are under the age of 18, please do not use or
        submit any personal information through our Website or mobile
        applications. We encourage parents and legal guardians to monitor their
        children’s Internet usage and to help enforce this Policy by instructing
        their children never to provide personal information through the
        Platforms without parental permission. If you have reasons to believe
        that a child under the age of 18 has provided personal information to us
        through the Platforms, please contact us at info@inpay.ng, and we will
        use commercially reasonable efforts to delete that information.
      </p>
      <h3 id='section-9'>9. Cookies</h3>
      <p>
        The information below applies to our website and mobile applications.
      </p>
      <p>
        Cookies and other similar technologies help to make our services better
        to use by doing things like recognizing when you’ve signed in, analyzing
        how you use our services so we can make them more useful and valuable to
        you as well as giving you a more personalized experience.
      </p>
      <p>
        When you interact with our online services, we obtain certain
        information using automated technologies, such as cookies, web server
        logs, web beacons and other technologies. A “cookie” is a text file that
        websites send to a visitor’s computer or other internet-connected device
        to uniquely identify the visitor’s browser or to store information or
        settings in the browser. A “web beacon,” also known as an internet tag,
        pixel tag, or clear GIF, is a tiny graphic image that may be used in our
        websites or emails.
      </p>
      <p>
        We use these automated technologies to collect your device information,
        internet activity information, and inferences as described above. These
        technologies help us to:
      </p>
      <ul className='bullet-outline'>
        <li>Remember your information so you do not have to re-enter it; </li>
        <li>
          Track and understand how you use and interact with our online services
          and emails;
        </li>
        <li>Tailor our online services to your preferences;</li>
        <li>
          Measure how useful and effective our services and communications are
          to you; and
        </li>
        <li>Otherwise manage and enhance our products and services.</li>
      </ul>

      <p>
        We set some of these automated technologies ourselves, but others are
        set by third parties who deliver services on our behalf. Some of these
        technologies may also be set by third parties that help us advertise our
        products and services to you as informed by your interests, based on
        information collected from your activity on the web, including but not
        limited to, browsing or purchasing products on or through our websites
        or on third party websites or your activity on mobile sites and
        applications. This advertising approach is called interest-based
        advertising or “targeted advertising.”
      </p>
      <p>
        Your browser can alert you when cookies are placed on your device, and
        how you can stop or disable them via your browser settings.
      </p>
      <p>
        Please note, however, that without cookies all of the features of our
        online services may not work properly. If you use a mobile device, you
        can manage how your device and browser share certain device data by
        changing the privacy and security settings on your mobile device.
      </p>
      <p>
        Within mobile applications, you may also go to your device settings and
        select “Limit Ad Tracking” (for iOS devices), or “Opt out of
        Interest-Based Ads” (for Android devices), which will allow you to limit
        our use of information collected from or about your mobile device (such
        as precise location data) for the purposes of serving online behavioral
        advertising to you.
      </p>
      <h3 id='section-10'>
        10. International Transfer of Personal Information
      </h3>
      <p>
        We may transfer and access your personal information from around the
        world, including from other countries in which we have our operations.
        We will protect your personal information in accordance with this Policy
        wherever it is processed and will take appropriate contractual or other
        steps required to protect the relevant personal information in
        accordance with applicable laws. We contractually obligate recipients of
        your personal information to agree to at least the same level of privacy
        safeguards as required under applicable data protection laws.
      </p>
      <h3 id='section-11'>11. Why we share your data</h3>
      <p>
        We take care to allow your personal information to be accessed only by
        those who really need access in order to provide services to you and to
        share with third parties who have a legitimate purpose for accessing it.
        We may share personal information about you with third parties in the
        following circumstances.
      </p>
      <p>
        <strong>Third-Party Service Providers.</strong> We share your personal
        information with our third-party service providers that we use to
        provide hosting for and maintenance of our Website, mobile application
        development, backup, storage, payment processing, banking service,
        identity verification, analytics and other services for us.
      </p>
      <p>
        These third-party service providers may have access to or process your
        personal information for the purpose of providing these services for us.
        We do not permit our third-party service providers to use the personal
        information that we share with them for their marketing purposes or for
        any other purpose other than in connection with the services they
        provide to us.
      </p>
      <p>
        <strong>
          Compliance with Laws and Law Enforcement Requests. To Protect Our
          Rights.
        </strong>
        In certain situations, we may be required to disclose personal
        information in response to lawful requests by public authorities,
        including to meet national security or law enforcement requirements. We
        may disclose personal information to respond to subpoenas, court orders,
        or legal process, or to establish or exercise our legal rights or defend
        against legal claims. We may also share such information if we believe
        it is necessary in order to investigate, prevent, or take action
        regarding illegal activities, suspected fraud, situations involving
        potential threats to the physical safety of any person, violations of
        our Service Agreement or our Websites’ Terms of Use, or as otherwise
        required by law.
      </p>
      <p>
        <strong>Interactive Areas.</strong> The Websites may offer feedback
        forms, comments sections, discussion forums, or other interactive
        features (“Interactive Areas”). You should be aware that any information
        that you post in an Interactive Area might be read, collected, and used
        by others who access it, in particular to Users who initiated the
        particular Interactive Area. To request the removal of your personal
        information from an Interactive Area, contact us at info@inpay.ng In
        some cases, we may not be able to remove your personal information, in
        which case we will let you know if we are unable to do so and why.
      </p>
      <p>
        <strong>With Your Consent.</strong> We may also share personal
        information with third parties other than the third parties mentioned in
        the above sections when we have your consent to do so.
      </p>
      <h3 id='section-12'>12. How to make a complaint</h3>
      <p>
        We’re extremely committed to respecting and protecting your information.
        If you have questions regarding your data privacy rights or would like
        to submit a related data privacy rights request, please contact us by:
      </p>
      <ul className='bullet-outline'>
        <li>Communicating with us via our app</li>
        <li>Sending an email to us at info@inpay.ng</li>
      </ul>

      <h3 id='section-13'>13. Changes to this document</h3>
      <p>
        Subject to applicable laws, we may revise this Privacy Policy at any
        time and in our sole discretion. When we revise this Privacy Policy, we
        will post the revised version on the Platforms and will update the date
        at the top of this Privacy Policy. We advise you to check this page
        often. The revised Privacy Policy will be effective upon posting via the
        Platforms, unless otherwise set forth therein or as otherwise required
        by applicable laws. You are free to decide whether or not to accept a
        revised version of this Privacy Policy, but accepting this Privacy
        Policy, as revised, is required for you to continue accessing or using
        our Services. If you do not agree to the terms of this Privacy Policy or
        any revised version of this Privacy Policy, your sole recourse is to
        terminate your access and use of our Services. Except as otherwise
        expressly stated by us, your access and use of our Services are subject
        to the version of this Privacy Policy in effect at the time of access or
        use.
      </p>
    </div>
  );
}

export default PrivacyText;
