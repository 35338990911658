// Inside a component
import React,{ useState } from 'react';
import './waitlist.css';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../assets/images/logo1.svg';
import waitlist_success from '../../assets/images/waitlist-success.gif';
import subscribers from '../../assets/images/subscribers.svg';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';





function Waitlist({clicked, setClicked}) {


    const [isEmail, setIsEmail] = useState(false);
    const [isPhone, setIsPhone] = useState(false);
    const [errors, setErrors] = useState({
        phone: false,
        email: false,
    })


    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
      });


    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
        if(event.target.name === "email" && event.target.value.length) {
            const email = event.target.value;
            const emailValid = emailTest(email);
            if(emailValid) {
                setErrors({
                    phone: formData.phoneNumber.length? !isPhone: false,
                    email: false
                })
            } else {
                setErrors({
                    phone: formData.phoneNumber.length? !isPhone: false,
                    email: true
                })
            }
        } else if ((event.target.name === "email") && !(event.target.value.length)) {
            setErrors({
                phone: formData.phoneNumber.length? !isPhone: false,
                email: false
            })
        }

        if(event.target.name === "phoneNumber" && event.target.value.length) {
            const phone = event.target.value;
            const phoneValid = phoneTest(phone);
            if(phoneValid) {
                setErrors({
                    email: formData.email.length? !isEmail: false,
                    phone: false
                })
            } else {
                setErrors({
                    email: formData.email.length? !isEmail: false,
                    phone: true
                })
            }
        } else if ((event.target.name === "phoneNumber") && !(event.target.value.length)) {
            setErrors({
                email: formData.email.length? !isEmail: false,
                phone: false,
            })
        }
    };


    function emailTest (email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isMail = re.test(email);
        if(isMail) {
            setIsEmail(true)
            return true;
        } else {
            setIsEmail(false)
            return false;
        }
    }

    function phoneTest (phone) {
        const validPhone = validatePhoneNumber(phone);
        if(validPhone) {
            setIsPhone(true)
            return true;
        } else {
            setIsPhone(false)
            return false;
        }
    }

    function validatePhoneNumber(phoneNumber) {
        // Basic pattern (NG-centric)
        const basicPattern = /^(\+)?234\d{10}|(?:^0)(?:7|8|9)(?:0|1)\d{8}$/;
      
        // Optional extensions for international formats
        const internationalExtensions = [
          // Example extensions: include relevant country codes and separators
          //UK
          /(?:\+?44\d{7}|\+?1\s?\d{3}[-\s]\d{3}[-\s]\d{4})/,
          //US
          /^(\+?\d{1,2}[- ]?)?(\([0-9]{3}\)[ ]?)?[0-9]{3}[ ]?-[ ]?[0-9]{4}$/,
          // ... add more extensions as needed
        ];
      
        // Combine basic pattern with optional extensions
        const combinedPattern = new RegExp(basicPattern.source + "|" + internationalExtensions.join("|"), "g");
      
        return combinedPattern.test(phoneNumber);
      }


    const open = clicked==="true"?true:false;

    const [open2, setClicked2] = useState(false);

    const handleClose = () => setClicked(false);

    const handleClickOpen2 = () => setClicked2(true);
    const handleClose2 = () => setClicked2(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(!(isEmail && isPhone)) return;
        const result = await AddContact(formData);
        if(!result) return toast("An error occured. Please try again", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            pauseOnHover: true,
            hideProgressBar: true,
            transition: Slide,

          });
        window.zcAction('joinwaitlist');
        handleClose();
        handleClickOpen2();
    };

    return (
        <div>
            <center>

            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={true} Backdrop={false} className="dialog">
            <DialogTitle justifyContent={'center'} align='center'>
            <img src={logo} className="wl-app-logo" alt="logo" width={70}/>
            </DialogTitle>

            <DialogContent>
            <DialogContentText align='center' class='wl-title'>Join the waitlist for iNPAY</DialogContentText>
            <DialogContentText align='center' class='wl-subtitle'>
            Join our waitlist now to be among the first to experience our innovative features designed to provide comfort.
            </DialogContentText>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <br/>
            <form onSubmit={handleSubmit}>
            <div className="container iw">
                <div className="row">

                <div className="col-sm-12 col-md-6">
                            <div className="waitlist-label">First Name</div>
                            <div className="input-group mt-3 emailField">
                            <input type="text" required id ='firstName' name = 'firstName' value={formData.firstName} onChange={handleChange} style={{border:'none',fontSize:16}} className="form-control email-roundBorder" placeholder="Enter first name" aria-label="Enter first name" aria-describedby="basic-addon2"/>
                            </div>
                            <br />
                    </div>

                    <div className="col-sm-12 col-md-6">
                            <div className="waitlist-label">Last Name</div>
                            <div className="input-group mt-3 emailField">
                            <input type="text" required id = 'lastName' name = 'lastName' value={formData.lastName} onChange={handleChange} style={{border:'none',fontSize:16}} className="form-control email-roundBorder" placeholder="Enter last name" aria-label="Enter last name" aria-describedby="basic-addon2"/>
                            </div>
                            <div className='p-2' />
                    </div>
                    <div className="col-12">
                        <div className="waitlist-label">Whatsapp Phone Number</div>
                        <div className="input-group mt-3 emailField"> 
                            <input type="text" required id = 'phoneNumber' name = 'phoneNumber' value={formData.phoneNumber} onChange={(e) => handleChange(e)} style={{border:'none',fontSize:16}} className="form-control email-roundBorder" placeholder="Enter whatsapp phone number" aria-label="Enter whatsapp phone number" aria-describedby="basic-addon2"/>
                        </div>
                        <div className='p-2' />
                        <div style={{marginTop:"-5px", marginLeft:"0px"}}>
                            <span style={{textAlign: "left", color:"red", fontSize:"13px", fontFamily:"DM Sans"}}>{(errors.phone) && "Please enter a valid phone number"}</span>
                        </div>
                    </div>
                   
                    <div className="col-12">
                        <center>
                            <div className="waitlist-label">Email Address</div>
                            <div className="input-group mt-3 emailField">
                            <input type="text" required id = 'email' name = 'email' value={formData.email} onChange={(e)=>handleChange(e)} style={{border:'none',fontSize:16}} className="form-control email-roundBorder" placeholder="Enter email address" aria-label="Enter email" aria-describedby="basic-addon2"/>
                            <div className="input-group-append">
                                <button type = "submit" className="input-group-text email-roundBorder email-button" style={{border:'none'}} id="basic-addon2">SUBSCRIBE</button>
                            </div>
                            </div>
                        </center>
                        <div style={{marginTop:"10px", marginLeft:"0px"}}>
                            <span style={{textAlign: "left", color:"red", fontSize:"13px", fontFamily:"DM Sans"}}>{(errors.email) && "Please enter a valid email"}</span>
                        </div>
                    </div>
                    <ToastContainer     
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                        theme="light"
                        transition = {Slide} />
                </div>
            </div>
            </form>
            

            <div className='p-4' />
            <center>
                <div className='wl-footer'>
            <img src={subscribers} className="App-subscribers" alt="subscribers" /> &nbsp; Join the 1,000+ people who have subscribed
            </div>
            </center> 

            <div className='p-2' />
            </DialogContent>
        </Dialog>


        <Dialog open={open2} onClose={handleClose2} fullWidth={true} maxWidth={true}  className="dialog">
            <DialogTitle justifyContent={'center'} align='center'>
            <img src={logo} className="wl-app-logo" alt="logo" width={70}/>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose2}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            

            <br />
            <br />
            <center>
            <img src={waitlist_success} alt="Success" className='wl-center-image'/>
            <br />
            <br />

            <div className="container">
            <DialogContentText align='center' class='wl-success-title'>We’ve added you to our<br />waiting list!</DialogContentText>
            <DialogContentText align='center' class='wl-success-subtitle'>We’ll let you know when iNPAY is ready. </DialogContentText>
            
            </div>
            </center> 

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </Dialog>
            </center>
        </div>
    );
}

async function  AddContact (formData) {
    var fdata = JSON.stringify({
        "Contact Email" : formData["email"],
        "First Name" : formData["firstName"],
        "Last Name" : formData["lastName"],
        "Phone" : formData["phoneNumber"],
    });
    try {
        const response = await fetch('https://sandbox.inpaybackend.com/add-contact', {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body : fdata
        });
  
        if (!response.ok) {
          throw new Error(`An error occured. Please try again`);
        }

        const data = await response.json();

        if(data['status']==="success") {
            return true;
        }else{

            throw new Error(`An error occured. Please try again`);        
        }
      } catch (error) {
        return false;
      }
}

export default Waitlist;