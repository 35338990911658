// Inside a component
import huffpost from "../../../assets/images/huffpost.svg";
import bloomberg from "../../../assets/images/bloomberg.svg";
import tc from "../../../assets/images/tc.svg";
import hbr from "../../../assets/images/hbr.svg";
import "./roll_of_logos.css";

function RollOfLogos() {
  return (
    <div className='roll-of-logos'>
      <div className='container-fluid '>
        <div className='row '>
          <div className='col-12 col-md-12 col-lg-4 pt-3 isl'>
            We're have been recognized by Leading <br />
            Finance Publications and Organizations.
            <div className='pt-3 d-md-none' />
          </div>

          <div className='col-12 col-md-12 col-lg-8'>
            <div className='iscroll'>
              <div className='scroll '>
                <img
                  className='scroll-item'
                  src={huffpost}
                  alt='huffpost'
                  srcSet=''
                />
                <img
                  className='scroll-item'
                  src={bloomberg}
                  alt='bloomberg'
                  srcSet=''
                />
                <img className='scroll-item' src={tc} alt='tc' srcSet='' />
                <img className='scroll-item' src={hbr} alt='hbr' srcSet='' />
                <img
                  className='scroll-item'
                  src={huffpost}
                  alt='huffpost'
                  srcSet=''
                />
                <img
                  className='scroll-item'
                  src={bloomberg}
                  alt='bloomberg'
                  srcSet=''
                />
                <img className='scroll-item' src={tc} alt='tc' srcSet='' />
                <img className='scroll-item' src={hbr} alt='hbr' srcSet='' />
                <img
                  className='scroll-item'
                  src={huffpost}
                  alt='huffpost'
                  srcSet=''
                />
                <img
                  className='scroll-item'
                  src={bloomberg}
                  alt='bloomberg'
                  srcSet=''
                />
                <img className='scroll-item' src={tc} alt='tc' srcSet='' />
                <img className='scroll-item' src={hbr} alt='hbr' srcSet='' />
              </div>
              <div className='fade'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RollOfLogos;
