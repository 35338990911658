function TermsText() {
  return (
    <div className='privacy-text-wrap'>
      <h2 id='heading'>Acceptable Use Policy</h2>
      <p>
        By accessing or using the Platform, you agree to comply with the terms
        and conditions of this Acceptable Use Policy (“Policy”). By accessing
        the Platform and the Services offered via the Platform, you agree to the
        latest version of this Policy. You acknowledge that you are solely
        responsible for complying with all applicable laws regarding your
        actions with respect to your use of the Services offered on the
        Platform.
      </p>
      <p>Last updated: December 5, 2024.</p>
      <h3 id='section-1'>1. General Restrictions</h3>
      <p>
        You may not use, facilitate the use or allow others to use the Platform
        or the Services accessible via the Platform for any of the activities
        outlined below. The list of activities set out below is representative
        and not exhaustive. Accordingly, by accessing the Platform and the
        Services, you acknowledge and agree not to use the Platform and any of
        the Services for the following:
      </p>
      <ol className='lower-alpha'>
        <li>
          <strong>Operating more than one (1) account with iNPAY –</strong>{" "}
          iNPAY accounts are specifically for person-to-person transfers with
          friends, family, third party whom you know or have business dealings
          with as well as to make authorized payments to merchants and billers.
        </li>
        <li>
          <strong>Illegal Activities –</strong> This would include activities
          that violate or support the violation of any laws or regulations. This
          also includes criminal or tortious activities such as child
          pornography, human trafficking or trafficking in obscene materials,
          drugs, gambling and other illegal activities.{" "}
        </li>
        <li>
          <strong>Abusive activities –</strong> these are activities that impose
          loads or restraints on our systems; interferes with our infrastructure
          or system operations; transmits or aids the transmission of harmful
          programs or materials that contain viruses, trojan horses, worms and
          other deleterious programs; attempt to gain unauthorized access to the
          Platform and our Services other Users’ accounts as well as networks
          and computer systems connected to the Platform and our Services by any
          means whatsoever.
        </li>
        <li>
          <strong>Abuse of other Users and our Employees</strong> by engaging in
          abusive activities, communications (whether written or oral) with
          regards to the use of the Services or interfering with the use and
          enjoyment of the Platform and services by other Users by any means
          whatsoever; or any hate speech or harm directed towards other Users or
          our employees. This includes without limitation defamation, extortion,
          harassment, stalking, threatening or otherwise violating or infringing
          the legal rights of others.
        </li>
        <li>
          <strong>Involvement in sanctioned activities</strong> such as
          activities that are in violation of international sanction regulations
          (with international application) as well as local sanction
          regulations. Activities under this heading include but are not limited
          to accessing the Platform or the Services for the benefit of an
          individual, entity or organization blocked, restricted or prohibited
          by applicable sanction authorities. If found to be in violation of
          these restrictions, your account could be terminated and your funds
          held for an indefinite period of time. In addition to this, we will
          also be obliged to file a report against you with the relevant
          authorities.
        </li>
        <li>
          <strong>Using the Platform for any transaction or activity</strong>{" "}
          which:
          <ul>
            <li>
              violates any law or government regulation, or promotes or
              facilitates such by third parties;
            </li>
            <li>is fraudulent, deceptive, unfair or predatory;</li>
            <li>
              causes or threatens reputational damage to us or the Platform;
            </li>
            <li>
              involves any of the prohibited business categories outlined in
              this Policy; or
            </li>
            <li>
              results in or creates a significant risk of fines, penalties,
              damages or other harm or liability.
            </li>
          </ul>
        </li>
      </ol>

      <h3 id='section-2'>2. Restriction of Certain Business Categories</h3>
      <p>
        You may not use the Platform or help or allow others use the Platform as
        well as the Services accessible via the Platform for or in connection
        with the following specifically prohibited business list. Breach or
        non-compliance with these provisions may result in termination or
        suspension of your account. Please note that the list set out below is
        not an exhaustive list and we/respective payment service providers
        reserve the right to add or amend this list any time. Your access and
        use of the Platform and the Services constitutes an unconditional
        acceptance and consent by you not to participate in or use our Platform
        and Services for the following:
      </p>

      <ol className='lower-alpha'>
        <li>
          Matters that fall within the Prohibition List of the Nigerian Customs
          Administration of the Federal Republic of Nigeria
        </li>
        <li>
          Matters that relate to the sale and/or purchase of:
          <ol className='lower-alpha'>
            <li>
              banned narcotics, steroids, certain controlled substances or other
              products that present a risk to a consumer's safety;
            </li>
            <li>blood, bodily fluids or body parts;</li>
            <li>burglary tools;</li>
            <li>counterfeit items;</li>
            <li>illegal drugs and drug paraphernalia;</li>
            <li>fireworks, destructive devices and explosives;</li>
            <li>
              identity documents, government documents, personal financial
              records or personal information (in any form, including mailing
              lists);
            </li>
            <li>
              lottery tickets, sweepstakes entries or slot machines without the
              required licence;
            </li>
            <li>
              offensive material or hate speech or items that promote hate,
              violence, racial intolerance, or the financial exploitation of a
              crime;
            </li>
            <li>chemicals;</li>
            <li>recalled items; </li>
            <li>prohibited services;</li>
            <li>unlicensed financial services, stocks or other securities;</li>
            <li>
              stolen property; o.items that infringe or violate any copyright,
              trademark, right of publicity or privacy or any other proprietary
              right under the laws of any jurisdiction;
            </li>
            <li>
              sales of currency without BDC licence, cryptocurrency operators;
            </li>
            <li>obscene material or pornography;</li>
            <li>certain sexually oriented materials or services;</li>
            <li>
              certain firearms, firearm parts or accessories, ammunition,
              weapons or knives; t.any product or service that is illegal or
              marketed or sold in such a way as to create liability to the
              Entities; or
            </li>
            <li>
              production of military and paramilitary wears and accoutrement,
              including those of the Police and the Customs, Immigration and
              Prison Services.
            </li>
          </ol>
        </li>
        <li>
          Matters that relate to transactions that:
          <ol className='lower-alpha'>
            <li>
              show the personal information of third parties in violation of
              applicable law;
            </li>
            <li>
              support pyramid or ponzi schemes, matrix programs, other 'get rich
              quick' schemes or certain multi-level marketing programs;
            </li>
            <li>
              are associated with purchases of annuities or lottery contracts,
              lay-away systems, off-shore banking or transactions to finance or
              refinance debts funded by a credit card;
            </li>
            <li>pertain to ammunitions and arms; and</li>
            <li>
              involve gambling, gaming and/or any other activity with an entry
              fee and a prize, including, but not limited to casino games,
              sports betting, horse or greyhound racing, lottery tickets, other
              ventures that facilitate gambling, games of skill (whether or not
              it is legally defined as a lottery) and sweepstakes unless the
              operator has obtained prior approval from the Entities and the
              operator and customers are located exclusively in jurisdictions
              where such activities are permitted by law.
            </li>
          </ol>
        </li>
      </ol>

      <h3 id='section-3'>3. Actions by Us</h3>
      <p>
        If, in our sole discretion, we believe that you may have engaged in any
        violation of this Acceptable Use Policy, we may (with or without notice
        to you) take such actions as we deem appropriate to mitigate any risk to
        us and any impacted third parties and to ensure compliance with this
        Acceptable Use Policy. Such actions may include, without limitation:
      </p>
      <ul className='bullet-outline'>
        <li>
          Placing a restriction on your account or blocking transactions and
          other activities on your account;
        </li>
        <li>
          Suspending, restricting or terminating your access to and use of your
          account, the Platform as well as the Services accessible via the
          Platform;
        </li>
        <li>
          Terminating our business relationship with you, including termination
          without liability to us of any service agreement between you and the
          Entities;
        </li>
        <li>Taking legal actions against you;</li>{" "}
        <li>
          Contacting and disclosing information related to such violations to
          (i) law enforcement or regulatory agencies, and (ii) other third
          parties that may have been impacted by such violations; or
        </li>
        <li>
          Assessing against you any fees, penalties, assessments or expenses
          (including reasonable attorneys’ fees) that we may incur as a result
          of such violations, which you agree to pay promptly upon notice.
        </li>
      </ul>

      <h3 id='section-4'>4. Updates, Modifications & Amendments</h3>
      <p>
        We may need to update, modify or amend our Acceptable Use Policy at any
        time. We reserve the right to make changes to this Acceptable Use
        Policy. We advise that you check this page often, referring to the date
        of the last modification on the page.
      </p>
    </div>
  );
}

export default TermsText;
