import './info.css';


function Info () {
    return (<div>
        <br/>
        
        <div className="info">
            <center>
                <div className='info-title d-md-none'>Questions, Concerns or Comments?</div>
                <div className='info-title d-none  d-md-block'>Do you have any Question, Concern or Comment?</div>
                <div className='info-subtitle'>Kindly use the form below to get in touch with us. Our office hours are Monday through Friday,  1am to 5pm and we respond to request within 3 business days.</div>
            </center>
        </div>
    </div>)
}

export default Info