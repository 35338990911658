import playstore_logo from "../../assets/images/playstore_logo.svg";

export function ActionButtonAndroid({ handleClick }) {
  return (
    <button
      type='button'
      className='btn hero-ibtn form-control whiteBg'
      style={{ backgroundColor: "white" }}
      onClick={handleClick}
    >
      <img className='store-img' src={playstore_logo} alt='' />
      <div className='store-text-wrap'>
        <span className='subtitle'>Download on</span>
        <span className='title'>Google Play</span>
      </div>
    </button>
  );
}
