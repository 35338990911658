import "./faqs.css";
import expand_icon from "../../../assets/images/expand-icon.svg";
import collapse_icon from "../../../assets/images/collapse-icon.svg";
import { useState } from "react";

function FaqItems({ question, answer }) {
  const [isVisible, setIsVisible] = useState(false);

  function changeState() {
    setIsVisible(!isVisible);
  }

  return (
    <div className='container'>
      <div className={`faq-container${isVisible ? " answer" : ""}`}>
        <div className='col-sm-12 col-md-12 col-lg-12'>
          <div className='faq-question-wrap' onClick={() => changeState()}>
            <div
              className='faq-question'
              style={isVisible ? { color: "white" } : {}}
            >
              {question}
            </div>
            {isVisible ? (
              <img src={collapse_icon} alt='' className='faq-arrow' />
            ) : (
              <img src={expand_icon} alt='' className='faq-arrow' />
            )}
          </div>
        </div>
        <div className={`faq-answer ${isVisible ? "faq-answer-show" : ""}`}>
          {answer}
        </div>
      </div>
    </div>
  );
}

export default FaqItems;
