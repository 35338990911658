import Footer from "../../../components/footer";
import HomePageNavbar from "../../../components/navbar";
import TableOfContents from "../../../components/table-of-contents";
import TermsText from "./term-text";
import "../styles/legal-general.css";

function TermsUsePage() {
  return (
    <div className='page-toc-wrap'>
      <HomePageNavbar />
      <TableOfContents />

      {/* Main Content */}
      <div className='privacy-page-container'>
        <TermsText />
      </div>

      <Footer />
    </div>
  );
}

export default TermsUsePage;
