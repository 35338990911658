// Inside a component
import { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo1.svg';
import './nav.css';
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'


function HomePageNavbar() {

    const [isOpen, setIsOpen] = useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

  return (
    <div className='white-bg fixed-top'>
        <div className='icontainer pt-sm-1 pt-md-2 pb-sm-1 pb-md-2'>
            <div className='navbar'>
            <Link to="/" className="plain-link"><img src={logo} className="App-logo" alt="logo" /></Link>
                

                    <div className='d-none d-md-block'>
                    <div className='navbar'>
                        <div className='navbar-item'>
                        <Link to="/" className="plain-link">Home</Link>
                        </div>
                
                        <div className='navbar-item'>
                        <Link to="/contact-us" className="plain-link">Contact Us</Link>
                        </div>
                        <div className='navbar-item'>
                        <Link to="/about-us" className="plain-link">About Us</Link>
                        </div>
                    </div>
                    </div>

                <div className='vempty d-none d-md-block'></div>

                <>
            <span style={{ fontSize: '35px', cursor: 'pointer' }} className='drawer-icon d-md-none' onClick={toggleDrawer}>&#9776;</span>
     
        </>
            </div>
        </div>
      

        <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='right'
                className='drawer'
            >
                <div className='menu-head'>Menu</div>
                <div className='menu-item'><Link to="/" className="plain-link">Home</Link></div>
                <div className='menu-item'><Link to="/contact-us" className="plain-link">Contact Us</Link></div>
                <div className='menu-item'><Link to="/about-us" className="plain-link">About Us</Link></div>
            </Drawer>
    </div>
  );
}

export default HomePageNavbar;