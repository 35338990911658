import Footer from "../../components/footer";
import HomePageNavbar from "../../components/navbar";
import DataDeletionRequestForm from "./deletion-form/form";

function DataDeletionPage() {
  return (
    <div>
      <HomePageNavbar />
      <DataDeletionRequestForm />
      <Footer />
    </div>
  );
}

export default DataDeletionPage;
