import React, { useEffect, useState } from "react";
import "./index.css";

const TableOfContents = ({
  selector = "h2, h3",
  rootMargin = "0px 0px -90% 0px",
}) => {
  const [headings, setHeadings] = useState([]);
  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    const elements = Array.from(document.querySelectorAll(selector));
    setHeadings(
      elements.map((el) => ({
        id: el.id,
        text: el.textContent,
        tag: el.tagName.toLowerCase(),
      }))
    );

    const observer = new IntersectionObserver(
      (entries) => {
        const visibleEntry = entries.find((entry) => entry.isIntersecting);
        if (visibleEntry) setActiveId(visibleEntry.target.id);
      },
      { rootMargin }
    );

    elements.forEach((el) => observer.observe(el));
    return () => observer.disconnect();
  }, [selector, rootMargin]);

  const handleClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const navbarHeight = 65;
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - navbarHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <nav className='toc'>
      <ul>
        {headings.map(({ id, text, tag }) => {
          return (
            <li
              key={`toc-${id}`}
              className={`toc-item toc-${tag} ${
                activeId === id ? "active" : ""
              }`}
              onClick={() => handleClick(id)}
            >
              {text}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default TableOfContents;
