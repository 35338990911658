import Footer from "../../components/footer";
import HomePageNavbar from "../../components/navbar";
import Header from "./header/index";
import Info from "./info/info";
import UserForm from "./user-form/user-form";

 
function ContactPage() {
  return (
    <div>
        <HomePageNavbar/>
        <Header/>
        <Info/>
        <UserForm/>
        <Footer/>
    </div>
  );
}

export default ContactPage;
